<template>
  <div id="Home">
    <div class="container is-max-desktop is-centered section">
      <div class="notification is-primary is-light has-text-centered">
        <p class="is-size-3">Hiana Souvenir</p>
      </div>
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <!-- STEP 1 - Data Customer -->
        <br />
        <b-step-item step="1" label="Profile" :clickable="isStepsClickable">
          <div class="field">
            <label class="is-size-5">Data Customer</label>
          </div>
          <b-field label="Name">
            <b-input
              v-model="name"
              placeholder="Nama Lengkap"
              type="text"
              required
            ></b-input>
          </b-field>
          <b-field label="Phone">
            <b-input
              v-model="phone"
              placeholder="No. Handphone / Whatsapp"
              type="number"
              required
            ></b-input>
          </b-field>
          <b-field label="Address">
            <b-input
              v-model="address"
              placeholder="Alamat Lengkap / Pengiriman"
              type="textarea"
            ></b-input>
          </b-field>
        </b-step-item>

        <!-- STEP 2 - Detail Pesenan -->
        <br />
        <b-step-item
          step="2"
          label="Item"
          :clickable="isStepsClickable"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <div class="field">
            <label class="is-size-5">Detail Pesanan</label>
          </div>

          <div v-for="(product, index) in products" :key="product.id">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-96x96">
                      <img :src="product.img" alt="Placeholder image" />
                    </figure>
                  </div>
                  <div class="media-content">
                    <b
                      ><p>Pesanan Ke {{ index + 1 }}</p></b
                    >
                    <p class="is-6">Product: {{ product.name }}</p>
                    <p>Qty: {{ product.qty }}</p>
                    <p>No. Design: {{ product.no_design }}</p>
                    <p>Description: {{ product.desc_design }}</p>
                    <p>Tinta Sablon: {{ product.color_design }}</p>
                    <br />
                    <button
                      class="button is-danger is-rounded"
                      v-on:click="deleteItem(index)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>

          <b-field label="Items">
            <b-autocomplete
              rounded
              v-model="item"
              :data="filteredDataArray"
              placeholder="Cari souvenir yang kamu mau. Contoh: Botol Kaktus"
              icon="magnify"
              clearable
              @select="(option) => (itemSelected = option)"
            >
              <template #empty>No results found</template>
            </b-autocomplete>
          </b-field>

          <b-field v-if="itemSelected">
            <figure class="card-image" style="max-width:100%;height:auto;width:auto;">
              <img :src="itemDetails.get_image" alt="Placeholder image" />
            </figure>
          </b-field>

          <b-field label="Qty">
            <b-input
              v-model="qty"
              placeholder="1000"
              type="number"
              required
            ></b-input>
          </b-field>

          <br>
          <b-field>
            <b-switch v-model="isPrinted"
              ><label v-if="!isPrinted">Tanpa Sablon</label
              ><label v-if="isPrinted">Dengan Sablon</label></b-switch
            >
          </b-field>

          <b-field
            v-show="isPrinted"
            label="No. Design"
            message="Isi 'Custom' untuk design sendiri & dikirim via email: hianasouvenir@gmail.com."
          >
            <b-input
              v-model="no_design"
              placeholder="OR001"
              type="text"
            ></b-input>
          </b-field>

          <b-field
            v-show="isPrinted"
            label="Design Color"
          >
            <b-select
              placeholder="Pilihan Warna Tinta Sablon"
              expanded
              v-model="color_design"
            >
              <option value="hitam">Hitam</option>
              <option value="biru">Biru</option>
              <option value="putih">Putih</option>
              <option value="merah">Merah</option>
              <option value="silver">Silver</option>
              <option value="gold">Gold</option>
              <option value="hijau">Hijau</option>
              <option value="pink">Pink</option>
              <option value="maroon">Maroon</option>
              <option value="donker">Donker</option>
              <option value="ungu">Ungu</option>
              <option value="ungu">Orange</option>
            </b-select>
          </b-field>

          <b-field
            v-show="isPrinted"
            label="Description Design"
          >
            <b-input
              v-model="desc_design"
              placeholder="The Wedding of
Rio & Hana
21 Januari 2017
Custom Ukuran Design: Tinggi: 4cm / Lebar: 1cm.
"
              type="textarea"
            ></b-input>
          </b-field>

          <br />
          <button v-on:click="addNewItem" class="button is-primary is-rounded">
            Tambah ke Keranjang
          </button>
          <div class="field">
            <br>
            <label class="is-size-7 has-text-danger">Note: Pastikan klik "Tambah Ke Keranjang" untuk semua item yang akan di pesan.</label>
          </div>
        </b-step-item>

        <!-- STEP 3 - Pengiriman -->
        <b-step-item
          step="3"
          label="Pengiriman"
          :clickable="isStepsClickable"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <div class="field">
            <label class="is-size-5">Detail Pengiriman</label>
          </div>
          <b-field
            label="Kurir Pengiriman"
            message="Pilih Gudang jika pengambilan mandiri di Gudang Hiana"
          >
            <b-select
              placeholder="Pilihan Jasa Pengiriman"
              expanded
              v-model="delivery"
            >
              <option value="Gudang">Gudang</option>
              <option value="Toko">Toko</option>
              <option value="ALS Kargo">ALS Kargo</option>
              <option value="Jarkasi">Jarkasi</option>
              <option value="MKS">MKS</option>
              <option value="Sindo Ekspres">Sindo Ekspres</option>
              <option value="HWU">HWU</option>
              <option value="Sentral Cargo">Sentral Cargo</option>
              <option value="Marnambur">Marnambur</option>
              <option value="Baraka">Baraka</option>
              <option value="Indah Kargo">Indah Kargo</option>
              <option value="Si Cepat">Si Cepat</option>
              <option value="Dakota">Dakota</option>
              <option value="KSI">KSI</option>
              <option value="JNE JTR">JNE JTR</option>
              <option value="Grab">Grab</option>
              <option value="Gojek">Gojek</option>
              <option value="Wahana">Wahana</option>
              <option value="J&T">J&T</option>
              <option value="Sindo Express">Sindo Express</option>
              <option value="Lalamove">Lalamove</option>
              <option value="KAI Logistik">KAI Logistik</option>
              <option value="Ninja Express">Ninja Express</option>
              <option value="Deliveree">Deliveree</option>
              <option value="Kurir Hiana Souvenir">Kurir Hiana Souvenir</option>
            </b-select>
          </b-field>
          <b-field label="Tanggal Selesai (Pengambilan/Kirim)">
            <b-datepicker
              placeholder="Tanggal Selesai / Pengambilan"
              v-model="deadline"
            >
            </b-datepicker>
          </b-field>
          <b-field label="Nama Sales">
            <b-select
              placeholder="Nama Sales"
              expanded
              v-model="seller"
            >
              <option value="ayu">Ayu</option>
              <option value="nilam">Rina</option>
              <option value="admin">Admin</option>
              <option value="munah">Munah</option>
              <option value="serli">Serli</option>
              <option value="yanti">Yanti</option>
              <option value="aryo">Aryo</option>
              <option value="yoga">Yoga</option>
              <option value="aldi">Aldi</option>
              <option value="puji">Vebi</option>
            </b-select>
          </b-field>
          <p>
            Dengan melakukan order menggunakan platform hianasouvenir.com
            pembeli setuju dengan
            <a href="https://hianasouvenir.com/term-conditions/" target="_blank"
              >Syarat & Ketentuan.</a
            >
          </p>
          <br />
          <div class="has-text-centered">
            <b-button type="is-success" @click="submit">Submit</b-button>
          </div>
        </b-step-item>

        <template v-if="customNavigation" #navigation="{ previous, next }">
          <b-button
            outlined
            type="is-danger"
            icon-pack="fas"
            icon-left="backward"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
            Previous
          </b-button>
          <b-button
            outlined
            type="is-success"
            icon-pack="fas"
            icon-right="forward"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            Next
          </b-button>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  data() {
    return {
      // Active step variables.
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",

      // Selected items
      data: [],
      itemSelected: "",
      itemList: "",
      itemDetails: "",
      item: "",
      no_design: "",
      desc_design: "",
      color_design: "",
      isPrinted: false,

      // Order Form
      name: "",
      phone: "",
      address: "",
      qty: 0,
      products: [],
      delivery: "",
      seller: "",
      deadline: new Date(),

      submitOrder: true,
    };
  },

  methods: {
    submit() {
      if (
        this.name == "" ||
        this.phone == "" ||
        this.delivery == ""
      ) {
        this.submitOrder = false;
        Toast.open({
          duration: 3000,
          message: "Pastikan mengisi form Nama, No. Telp & Kurir Pengiriman",
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.products.length == 0){
        this.submitOrder = false;
        Toast.open({
          duration: 3000,
          message: "Keranjang anda masih kosong, pastikan klik \"Tambah Ke Keranjang\" untuk semua item yang akan di pesan.",
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.submitOrder = true;
      }
      
      if (this.submitOrder) {
        axios
          .post("/api/v1/order/add/", {
            name: this.name,
            phone: this.phone,
            address: this.address,
            products: this.products,
            delivery: this.delivery,
            seller: this.seller,
            deadline: this.deadline,
          })
          .then((response) => {
            this.status = response.data;
            console.log(this.status);
            window.open("https://order.hianasouvenir.com/#/success", "_self");
          })
          .catch((error) => {
            console.log(error);
            Toast.open({
              duration: 5000,
              message: "Terjadi kesalahan ketika membuat pesanan!",
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
    },


    // Add New Item Handler
    addNewItem: function () {

      if (
        this.item == "" ||
        this.qty == ""
      ) {
        Toast.open({
          duration: 5000,
          message: "Cari item & tentukan jumlah item yg ingin di pesan",
          position: "is-bottom",
          type: "is-danger",
        });

        return NaN
      }

      // Add to product list
      this.products.push({
        name: this.itemSelected,
        qty: this.qty,
        img: this.itemDetails.get_image,
        no_design: this.no_design == "" ? "Tanpa Cetak" : this.no_design,
        desc_design: this.desc_design == "" ? "Tanpa Cetak" : this.desc_design,
        color_design: this.color_design == "" ? "Tanpa Cetak" : this.color_design
      });

      this.item = "";
      this.qty = 0;
      this.isPrinted = !this.isAnimated;
      this.no_design = "";
      this.desc_design = "";
      this.color_design = "";
    },

    // Delete Item Handler
    deleteItem: function (index) {
      this.products.splice(index, 1);
    },
  },

  computed: {
    filteredDataArray() {
      return this.data.filter((option) => {
        return (
          option.toString().toLowerCase().indexOf(this.item.toLowerCase()) >= 0
        );
      });
    },
  },

  watch: {
    itemSelected(value) {
      var index = null;

      for (var i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].name == value) {
          index = i;
          break;
        }
      }
      this.itemDetails = this.itemList[index];
    },
  },

  mounted() {
    axios
      .get("/api/v1/products/")
      .then((response) => {
        this.itemList = response.data;
        for (var i = 0; i < this.itemList.length; i++) {
          this.data.push(response.data[i].name);
        }
        console.log(this.temp);
      })
      .catch((error) => console.log(error));
  },
};
</script>

<style>
</style>